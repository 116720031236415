// src/components/modals/Modal.jsx
import React, { useState, useEffect } from 'react';

const Modal = ({ 
  isOpen, 
  onClose, 
  children, 
  showCloseButton = true, 
  showBackButton = false, 
  onBack, 
  title = ''
}) => {
  const [mounted, setMounted] = useState(isOpen);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setMounted(true);
      setVisible(false);
      // Forzamos un pequeño retardo para que el modal se renderice con opacity-0 antes de subir a 100.
      const timeout = setTimeout(() => {
        setVisible(true);
      }, 10);
      return () => clearTimeout(timeout);
    } else {
      // Al cerrar: ponemos visible en false para iniciar la transición.
      setVisible(false);
    }
  }, [isOpen]);

  const handleTransitionEnd = (e) => {
    // Verificamos si la transición terminó en el elemento raíz y es de la propiedad opacity.
    if (e.target === e.currentTarget && e.propertyName === 'opacity' && !isOpen) {
      // Si isOpen es falso y la transición ha terminado, desmontamos el modal.
      setMounted(false);
    }
  };

  if (!mounted) return null; // No renderizamos nada si no está montado

  return (
    <div
      className={`
        fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 
        transition-opacity duration-300 ease-in-out
        ${visible ? 'opacity-100' : 'opacity-0'}
      `}
      onTransitionEnd={handleTransitionEnd}
    >
      <div className="bg-white rounded-lg shadow-lg w-1/2 h-1/2 relative overflow-auto p-6 transition-transform duration-300 ease-in-out">
        
        {/* Barra superior */}
        <div className="flex items-center justify-between mb-2">
          {showBackButton ? (
            <button
              onClick={onBack}
              className="bg-gray-200 hover:bg-gray-300 text-black px-3 py-1 rounded"
            >
              Atrás
            </button>
          ) : (
            <div className="w-12"></div>
          )}

          <h2 className="font-bold text-xl text-center flex-grow text-black">
            {title}
          </h2>

          {showCloseButton ? (
            <button
              onClick={onClose}
              className="bg-gray-200 hover:bg-gray-300 text-black px-3 py-1 rounded"
            >
              Cerrar
            </button>
          ) : (
            <div className="w-12"></div>
          )}
        </div>

        {/* Línea separadora */}
        <hr className="my-2 border-gray-300" />

        {/* Contenido del modal */}
        <div>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;